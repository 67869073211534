import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { IonGrid, IonRow, IonCol, IonLabel, IonSelect, IonSelectOption, IonRadioGroup, IonRadio,IonItem } from '@ionic/react';
import { saveWelcomePage, saveOwnerQuestionnairePage } from '../../helpers/calloutHelpers';
import { InitSessionApp } from '../../helpers/sessionInterfaces';
import {connect } from 'react-redux';
import * as actions from '../../store/actions';

interface OwnerInfoPage extends InitSessionApp {
    welcomePageFields: welcomePageParameters,
    formData?: {data?: Forms.applicantOwnerQuestionnaireForm},
    setShowSpinner: Function,
    setWelcomePageFields: (payload: Partial<welcomePageParameters>) => void,
}

const OwnerQuestionnaire: React.FC<OwnerInfoPage> = ({sessionId, updateMenuItem, formRef, setShowSpinner, welcomePageFields, setWelcomePageFields, formData,  handleNavigation, setErrorMessage, setShowErrorToast}) => {
    const getDefaultValues = ()=>{
        let defaultForm: Forms.applicantOwnerQuestionnaireForm ={
            occupation: '',
            household_income: '',
            net_worth: '',
            projected_retirement: '',
            ...formData?.data
        }
        return defaultForm
    }

    const {control, handleSubmit, formState} = useForm<Forms.applicantOwnerQuestionnaireForm>({
        mode: "onChange",
        defaultValues: getDefaultValues()
    });

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const { isDirty } = formState;

    const save = (form:Forms.applicantOwnerQuestionnaireForm) => {
        setShowSpinner(true);
        saveWelcomePage(sessionId, welcomePageFields)?.then(()=>{
            saveOwnerQuestionnairePage(sessionId, form)?.then(()=>{
                updateMenuItem('is_owner_questionnaire_page_valid', true, sessionId);
            }).finally(() => {
                setShowSpinner(false);
                handleNavigation({status: 'Success'});  
            })
        })
    }

    const handleIsSelfEmployedChange = (e:any) => {
        setWelcomePageFields({is_self_employed: e.target.value});
    }

    const onFormInvalid = () => {
        if (isDirty) {
            setErrorMessage('');
            setShowErrorToast(true);
        }
        else {
            handleNavigation({status: 'Error'});
        }
    }

    return (
        <form ref={formRef} onSubmit={handleSubmit(save,onFormInvalid)} className='ion-padding'>
            <IonGrid>
                <IonRow className="well">
                    <IonCol>
                        <strong><p>The following questions are asked to help Equity Trust better understand you and tailor our service accordingly.</p>
                        <p>Answering these questions is completely voluntary. Per our privacy policy, Equity Trust does not share or sell your personal information.</p></strong>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                        <IonLabel>Occupation</IonLabel>
                        <IonItem>
                            <Controller
                                name={'occupation'}
                                control={control}
                                render={({value, name, onBlur, onChange})=>
                                <IonSelect name={name} interface='action-sheet' interfaceOptions={{animated: false, mode: 'ios'}} value={value} onIonChange={onChange} onIonBlur={onBlur}>
                                    <IonSelectOption value="Accountant">Accountant</IonSelectOption>
                                    <IonSelectOption value="Attorney">Attorney</IonSelectOption>
                                    <IonSelectOption value="Financial Advisor">Financial Adviser</IonSelectOption>
                                    <IonSelectOption value="Realtor">Realtor</IonSelectOption>
                                    <IonSelectOption value="Other">Other</IonSelectOption>
                                </IonSelect>}/>
                        </IonItem>
                    </IonCol>
                    <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                        <IonLabel>Are you Self-Employed? &nbsp;</IonLabel> 
                        <div className="ion-text-wrap">
                            <IonRadioGroup name='is_self_employed' onIonChange={handleIsSelfEmployedChange} value={welcomePageFields.is_self_employed} >
                                <IonLabel>Yes</IonLabel>
                                <IonRadio mode="md" value={true} className='ion-margin-horizontal'/>
                                &nbsp;
                                <IonLabel>No</IonLabel>
                                <IonRadio mode="md" value={false} className='ion-margin-horizontal'/>
                            </IonRadioGroup>
                        </div>               
                    </IonCol> 
                </IonRow>
                <IonRow>
                    <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                        <IonLabel>Household Income</IonLabel>
                        <IonItem>
                            <Controller
                                name={'household_income'}
                                control={control}
                                render={({value, name, onBlur, onChange})=>
                                <IonSelect name={name} interface='action-sheet' interfaceOptions={{animated: false, mode: 'ios'}} value={value} onIonChange={onChange} onIonBlur={onBlur}>
                                    <IonSelectOption value="0-50k">0-50k</IonSelectOption>
                                    <IonSelectOption value="50-100k">50-100k</IonSelectOption>
                                    <IonSelectOption value="100-200k">100-200k</IonSelectOption>
                                    <IonSelectOption value="200-400k">200-400k</IonSelectOption>
                                    <IonSelectOption value="400k+">400k+</IonSelectOption>
                                </IonSelect>}/>
                        </IonItem>
                    </IonCol>
                    <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                        <IonLabel>Net Worth</IonLabel>
                        <IonItem>
                            <Controller
                                name={'net_worth'}
                                control={control}
                                render={({value, name, onBlur, onChange})=>
                                <IonSelect name={name} interface='action-sheet' interfaceOptions={{animated: false, mode: 'ios'}} value={value} onIonChange={onChange} onIonBlur={onBlur}>
                                    <IonSelectOption value="0-250k">0-250k</IonSelectOption>
                                    <IonSelectOption value="250-500k">250-500k</IonSelectOption>
                                    <IonSelectOption value="500k-1M">500k-1M</IonSelectOption>
                                    <IonSelectOption value="1M-2M">1M-2M</IonSelectOption>
                                    <IonSelectOption value="2-5M">2-5M</IonSelectOption>
                                    <IonSelectOption value="5M+">5M+</IonSelectOption>
                                </IonSelect>}/>
                        </IonItem>
                    </IonCol>
                    <IonCol size="6" sizeMd="6" sizeSm="12" sizeXs="12">
                        <IonLabel>Projected Retirement</IonLabel>
                        <IonItem>
                            <Controller
                                name={'projected_retirement'}
                                control={control}
                                render={({value, name, onBlur, onChange})=>
                                <IonSelect name={name} interface='action-sheet' interfaceOptions={{animated: false, mode: 'ios'}} value={value} onIonChange={onChange} onIonBlur={onBlur}>
                                    <IonSelectOption value="Currently Retired">Currently Retired</IonSelectOption>
                                    <IonSelectOption value="1-5 Years">1-5 Years</IonSelectOption>
                                    <IonSelectOption value="6-10 Years">6-10 Years</IonSelectOption>
                                    <IonSelectOption value="11-20 Years">11-20 Years</IonSelectOption>
                                    <IonSelectOption value="20+ Years">20+ Years</IonSelectOption>
                                </IonSelect>}/>
                        </IonItem>
                    </IonCol>
                </IonRow>
            </IonGrid>                
        </form>
    )
}

const mapStateToProps = (state: StoreState) => {
    return {
        welcomePageFields: state.welcomePage,
        is401kApplication: state.menu.menuParams.is401kApplication,
        isDYO401kApplication: state.menu.menuParams.isDYO401kApplication,
        sessionId: state.session.sessionId
    }
}

const mapDispatchToProps = (dispatch: Function) => {
    return {
        setWelcomePageFields: (payload: any) => dispatch(actions.setWelcomePageFields(payload)),
        setShowSpinner: (showSpinner: boolean) => dispatch(actions.setShowSpinner(showSpinner)),
        setShowErrorToast: (payload: any) => dispatch(actions.setShowErrorToast(payload)),
        setErrorMessage: (payload: any) => dispatch(actions.setErrorMessage(payload)),
        updateMenuItem: (page: keyof MenuParameters, valid: boolean, sessionId: string) => dispatch(actions.updateValidStateOnMenu({page, valid, sessionId})),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OwnerQuestionnaire);