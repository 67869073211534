import { TOTAL_FEES } from './sharedUtils'
export const today = `${String(new Date().getMonth()+ 1)}/${String(new Date().getDate())}/${String(new Date().getFullYear())}`;

export const SF_SYSTEM_USER_ID = '0053000000AinCwAAJ'

export interface sharedParameters {
    initial_investment_type: string,
    applicant_email: string
}

export interface saveApplicationId extends API.RequestBody {
    data: Forms.applicantIdForm
}

export interface FormData {
    [key:string] : any
}

export interface beneficiaryForm{
    beneficiary_count: number
    beneficiaries: Array<SubForms.beneficiary>
}

export interface saveFeeArrangement extends API.RequestBody {
    data: Forms.feeArrangementForm
}

export interface PaymentBody{
    sessionId: string,
    creditCardNumber: string,
    expirationDateString: string,
    feeAmount: number,
    discountAmount: number
}

export interface feeArrangementFeeScheduleOnlyForm {
    fee_schedule: string|null
}

export interface transferForm{
    existing_transfers: number,
    account_type: string,
    transfers: Array<SubForms.transfer>
}

export type taxYearOptions = ['Current Year', 'Last Year'] | ['Current Year']

export interface contributionOptions {
    taxYears?: taxYearOptions, 
    contributionLimit?: number
}

export interface rolloverForm{
    account_type: string,
    existing_employer_plan_roll_overs: number,
    rollovers: Array<SubForms.rollover>
}

export interface PromoCodeData{
    name: string|null,
    discount_amount: number|null,
    payment_options: string|null,
    salesforce_id: string|null
    promoCodeLoaded: boolean
}

export interface headerControls{
    'nextButton':{hasNextBeenClicked: boolean, setHasNextBeenClicked: Function},
    'prevButton':{hasPrevBeenClicked: boolean, setHasPrevBeenClicked: Function},
    'saveButton':{hasSaveBeenClicked: boolean, setHasSaveBeenClicked: Function}
}

export const states = [ 'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA',  'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY' ];

//TODO: This should be one of the first functions we add tests around - to ensure we are calculating the fee correctly.
//Along with code that references this, to ensure the paramaters are passed in correctly
export function getTotalFee(is401kApplication: Boolean, isDyo401kApplication: Boolean) {
    let totalFee = TOTAL_FEES.FEE_IRA;
    if (is401kApplication || isDyo401kApplication) {
        totalFee = isDyo401kApplication ? TOTAL_FEES.FEE_401K_DYO : TOTAL_FEES.FEE_401K;
    }

    return totalFee;
}

//TODO: This should be one of the first functions we add tests around - to ensure we are calculating the fee correctly.
//Along with code that references this, to ensure the paramaters are passed in correctly

export const isMobile = (isPlatform: Function) => {
    return (isPlatform('iphone') || isPlatform('android') || isPlatform('ipad'));
  }

export const removeRowsFromStateAndView = (rowNumberToRemove: number, setFormData: Function, setValue: Function, keyOfCurrentCount: string)  => {
    setFormData((prevState: any) => {
        let currentCount = prevState[keyOfCurrentCount]; 
        let newCount = currentCount - 1; 
        let currentState = {...prevState};
        currentState[keyOfCurrentCount] = newCount; 

        let currentStateKeys = Object.keys(currentState); 
        
        currentStateKeys.forEach(key => {
            if (key.includes(`__${rowNumberToRemove}`)) {
                setValue(key, ''); 
                delete currentState[key];
            }
        })
        // Shifting Beneficiary/Transfer/Rollover Info 
        if (currentCount > 1 && rowNumberToRemove !== currentCount) {
            for (let indexToReplace = rowNumberToRemove + 1; indexToReplace <= currentCount; indexToReplace++) {
                currentStateKeys.forEach(key=> {
                    if (key.includes(`__${indexToReplace}`)) {
                            let newKey = key.replace(`__${indexToReplace}`,`__${indexToReplace - 1}`);
                            currentState[newKey] = currentState[key];
                            setValue(newKey, currentState[key]);
                            delete currentState[key]; 
                    }
                })
            }
        }

        return {
            ...currentState
        }
    });
}

export const formatDefaultDateString = (dateString: string) => {
        let dateArray = dateString.split('-');
        let convertedDate = new Date(+dateArray[0], +dateArray[1]-1, +dateArray[2]);
        return convertedDate.toDateString();
}

export const formatDateToString = (date: Date) => {
    return [
        String(date.getFullYear()),
        String(101 + date.getMonth()).substring(1),
        String(100 + date.getDate()).substring(1),
    ].join('-');
}

export const validateDate = (val: Date, minDate: Date, maxDate: Date) => {
    let isValid = true; 
    isValid = isValid && (val >= minDate); 
    isValid = isValid && (val <= maxDate);
    return isValid; 
}

const getLastDayOfMonth = (month : number, year : number) => {
    return new Date(year, month + 1, 0).getDate();
}

export function validateCreditCardExpirationDate(val : any) {
    let dateArr = val.split('-');
    let expirationDate = new Date(dateArr[0], dateArr[1] - 1, getLastDayOfMonth(dateArr[1], dateArr[0]));
    let today = new Date();
    let lastDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), getLastDayOfMonth(today.getMonth() + 1, today.getFullYear()));
    return (expirationDate >= lastDayOfCurrentMonth);
}

export const errorsExistForFieldOnForm = (errorsForAllForms: any, indexOfForm: number, fieldName: string) => {
    let errorsForForm = (errorsForAllForms === undefined || errorsForAllForms[indexOfForm] === undefined) ? {} : errorsForAllForms[indexOfForm];
    let errorKeys = (Object.keys(errorsForForm as any));
    return errorKeys.includes(fieldName);
}

export const currencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

export type ViewMode = 'reg'|'isp'

export const ParseQueryParams = (searchParams:URLSearchParams):onePathParameters=>{

    let onePathParams :onePathParameters = {
        hasOnePathParams: false
    }
    
    //NOTE: all params are case sensitive
    
    //NOTE: This param is not working
    if (searchParams.get('OwnerId')){
        onePathParams.owner_id = searchParams.get('OwnerId')
        onePathParams.hasOnePathParams = true
    }
    
    //NOTE: This param is not working
    if (searchParams.get('PromoCode')){
        onePathParams.promo_code = searchParams.get('PromoCode')
        console.log('promo code found ' + onePathParams.promo_code)
        onePathParams.hasOnePathParams = true
    }

    //NOTE: This param is not working, we also may not need to set DealOpp
    if (searchParams.get('DealOpp__c')){
        onePathParams.deal_opp = searchParams.get('DealOpp__c')
        onePathParams.hasOnePathParams = true
    }

    if (searchParams.get('Initial_Investment_Type__c')){
        onePathParams.initial_investment_type = searchParams.get('Initial_Investment_Type__c')
        onePathParams.hasOnePathParams = true
    }
    
    if (searchParams.get('Initial_Investment_Name__c')){
        onePathParams.initial_investment_name = searchParams.get('Initial_Investment_Name__c')
        onePathParams.hasOnePathParams = true
    }
    
    if (searchParams.get('Investment_Contact_Person__c')){
        onePathParams.investment_contact_person = searchParams.get('Investment_Contact_Person__c')
        onePathParams.hasOnePathParams = true
    }
    
    if (searchParams.get('Investment_Contact_Person_Phone__c')){
        onePathParams.investment_contact_person_phone = searchParams.get('Investment_Contact_Person_Phone__c')
        onePathParams.hasOnePathParams = true
    }
    
    if (searchParams.get('ReferredBy')){
        onePathParams.referred_by = searchParams.get('ReferredBy')
        onePathParams.hasOnePathParams = true
    }

    return onePathParams
}